exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-club-contact-tsx": () => import("./../../../src/pages/club/contact.tsx" /* webpackChunkName: "component---src-pages-club-contact-tsx" */),
  "component---src-pages-club-downloads-tsx": () => import("./../../../src/pages/club/downloads.tsx" /* webpackChunkName: "component---src-pages-club-downloads-tsx" */),
  "component---src-pages-club-history-tsx": () => import("./../../../src/pages/club/history.tsx" /* webpackChunkName: "component---src-pages-club-history-tsx" */),
  "component---src-pages-club-index-tsx": () => import("./../../../src/pages/club/index.tsx" /* webpackChunkName: "component---src-pages-club-index-tsx" */),
  "component---src-pages-club-register-tsx": () => import("./../../../src/pages/club/register.tsx" /* webpackChunkName: "component---src-pages-club-register-tsx" */),
  "component---src-pages-club-ultras-tsx": () => import("./../../../src/pages/club/ultras.tsx" /* webpackChunkName: "component---src-pages-club-ultras-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jeugd-tsx": () => import("./../../../src/pages/jeugd.tsx" /* webpackChunkName: "component---src-pages-jeugd-tsx" */),
  "component---src-pages-kiosk-a-tsx": () => import("./../../../src/pages/kiosk/a.tsx" /* webpackChunkName: "component---src-pages-kiosk-a-tsx" */),
  "component---src-pages-kiosk-b-tsx": () => import("./../../../src/pages/kiosk/b.tsx" /* webpackChunkName: "component---src-pages-kiosk-b-tsx" */),
  "component---src-pages-kiosk-previous-tsx": () => import("./../../../src/pages/kiosk/previous.tsx" /* webpackChunkName: "component---src-pages-kiosk-previous-tsx" */),
  "component---src-pages-kiosk-upcoming-tsx": () => import("./../../../src/pages/kiosk/upcoming.tsx" /* webpackChunkName: "component---src-pages-kiosk-upcoming-tsx" */),
  "component---src-pages-scheurkalender-tsx": () => import("./../../../src/pages/scheurkalender.tsx" /* webpackChunkName: "component---src-pages-scheurkalender-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-news-overview-tsx": () => import("./../../../src/templates/NewsOverview.tsx" /* webpackChunkName: "component---src-templates-news-overview-tsx" */),
  "component---src-templates-news-tag-page-tsx": () => import("./../../../src/templates/NewsTagPage.tsx" /* webpackChunkName: "component---src-templates-news-tag-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-player-share-tsx": () => import("./../../../src/templates/PlayerShare.tsx" /* webpackChunkName: "component---src-templates-player-share-tsx" */),
  "component---src-templates-player-tsx": () => import("./../../../src/templates/Player.tsx" /* webpackChunkName: "component---src-templates-player-tsx" */),
  "component---src-templates-staff-tsx": () => import("./../../../src/templates/Staff.tsx" /* webpackChunkName: "component---src-templates-staff-tsx" */),
  "component---src-templates-static-ranking-tsx": () => import("./../../../src/templates/StaticRanking.tsx" /* webpackChunkName: "component---src-templates-static-ranking-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/Team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

